<template>
    <vx-card no-shadow>
        <div v-if="notificationData" id="user-edit-tab-info">
            <div class="vx-row">
                <div class="vx-col w-full md:w-1/2 mb-base">
                    <!-- Col Header -->
                    <div class="flex items-end md:mt-0 mt-base">
                        <feather-icon
                            icon="BellIcon"
                            class="mr-2"
                            svgClasses="w-5 h-5"
                        />
                        <span class="leading-none font-medium"
                            >Notifications</span
                        >
                    </div>

                    <!-- Account Content -->
                    <div class="mb-base">
                        <div class="flex mt-8">
                            <label for="" class="mr-auto">Wills</label>
                            <vs-switch
                                color="success"
                                v-model="notificationData.wills"
                                vs-icon="done"
                            >
                            </vs-switch>
                        </div>
                        <div class="flex mt-8">
                            <label for="" class="mr-auto">Emails</label>
                            <vs-switch
                                color="success"
                                v-model="notificationData.emails"
                                vs-icon="done"
                            >
                            </vs-switch>
                        </div>
                        <div class="flex mt-8">
                            <label for="" class="mr-auto">Payments</label>
                            <vs-switch
                                color="success"
                                v-model="notificationData.payments"
                                vs-icon="done"
                            >
                            </vs-switch>
                        </div>
                        <div class="flex mt-8">
                            <label for="" class="mr-auto">Advice</label>
                            <vs-switch
                                color="success"
                                v-model="notificationData.advice"
                                vs-icon="done"
                            >
                            </vs-switch>
                        </div>
                        <div class="flex mt-8">
                            <label for="" class="mr-auto">Subscriptions</label>
                            <vs-switch
                                color="success"
                                v-model="notificationData.subscriptions"
                                vs-icon="done"
                            >
                            </vs-switch>
                        </div>
                        <div class="flex mt-8">
                            <label for="" class="mr-auto">Users</label>
                            <vs-switch
                                color="success"
                                v-model="notificationData.users"
                                vs-icon="done"
                            >
                            </vs-switch>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="flex flex-wrap items-center">
                    <vs-button
                        class="ml-auto mt-2"
                        type="flat"
                        color="primary"
                        icon-pack="feather"
                        icon="icon-chevron-left"
                        v-on:click="$emit('back')"
                        >Back</vs-button
                    >
                    <vs-button
                        class="bg-primary-gradient ml-4 mt-2"
                        icon-pack="feather"
                        icon="icon-save"
                        v-on:click="save"
                        >Save</vs-button
                    >
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import { http } from '@/services'

export default {
    name: 'NotificationTab',
    props: ['isSmallerScreen'],
    mounted() {
        this.notificationDataFetch()
    },
    data() {
        return {
            notificationData: null,
        }
    },
    methods: {
        save() {
            http.patch('user_staff_notifications', this.notificationData)
                .then((response) => {
                    this.$vs.notify({
                        text: 'Notification preferences saved',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'danger',
                    })
                })
        },
        notificationDataFetch() {
            http.get('user_staff_notifications')
                .then((response) => {
                    this.notificationData = response.data
                })
                .catch((error) => console.log(error))
        },
    },
}
</script>

<style></style>
