<template>
    <div id="page-user-edit">
        <div
            slot="no-body"
            class="tabs-container px-6 pt-6 vs-con-loading__container"
            id="div-with-loading"
        >
            <vs-tabs
                :position="isSmallerScreen ? 'top' : 'left'"
                class="tabs-shadow-none"
                id="profile-tabs"
                :key="isSmallerScreen"
            >
                <vs-tab
                    :label="!isSmallerScreen ? 'Notifications' : ''"
                    icon-pack="feather"
                    icon="icon-bell"
                >
                    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                        <NotificationTab
                            :isSmallerScreen="isSmallerScreen"
                            @back="back"
                        />
                    </div>
                </vs-tab>
                <vs-tab
                    :label="!isSmallerScreen ? 'Avatar' : ''"
                    icon-pack="feather"
                    icon="icon-image"
                >
                    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                        <UserTab
                            :isSmallerScreen="isSmallerScreen"
                            @back="back"
                        />
                    </div>
                </vs-tab>
            </vs-tabs>
        </div>
    </div>
</template>
]

<script>
import NotificationTab from './NotificationTab'
import UserTab from './UserTab'

export default {
    name: 'Profile',
    props: ['data'],
    components: {
        UserTab,
        NotificationTab,
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    data() {
        return {
            backPath: null,
            activeTab: 0,
        }
    },
    computed: {
        isSmallerScreen() {
            return this.$store.state.windowWidth < 768
        },
    },
    methods: {
        back() {
            if (this.backPath) this.$router.push(this.backPath)
            else this.$router.push('/users/staff')
        },
    },
}
</script>

<style lang="scss">
#avatar-col {
    width: 10rem;
}

#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: 0.8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
    only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }
}
</style>
