<template>
    <vx-card no-shadow v-if="userData">
        <!-- Col Header -->
        <div class="flex items-end md:mt-0 mt-base mb-3">
            <feather-icon icon="ImageIcon" class="mr-2" svgClasses="w-5 h-5" />
            <span class="leading-none font-medium">Avatar</span>
        </div>

        <BtnImageSelect
            :logo="userData.profile_image"
            @logoChanged="userData.profile_image = $event"
            :acceptFiles="'image/*'"
        ></BtnImageSelect>
        <div class="vx-row">
            <div class="mt-8 flex flex-wrap items-center">
                <vs-button
                    class="ml-auto mt-2"
                    type="flat"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-chevron-left"
                    v-on:click="$emit('back')"
                    >Back</vs-button
                >
                <vs-button
                    class="bg-primary-gradient ml-4 mt-2"
                    icon-pack="feather"
                    icon="icon-save"
                    v-on:click="save"
                    >Save</vs-button
                >
            </div>
        </div>
    </vx-card>
</template>

<script>
import { http } from '@/services'

import BtnImageSelect from '@/components/common/BtnImageSelect'

export default {
    name: 'UserTab',
    props: ['isSmallerScreen'],
    components: {
        BtnImageSelect,
    },
    mounted() {
        this.userDataFetch()
    },
    data() {
        return {
            userData: null,
        }
    },
    methods: {
        save() {
            var formData = new FormData()
            if (typeof this.userData.profile_image === 'string')
                delete this.userData.profile_image
            for (const property in this.userData) {
                if (this.userData[property] !== null)
                    formData.append(
                        property.toString(),
                        this.userData[property]
                    )
            }
            http.patch('user_staff', formData)
                .then((response) => {
                    this.$vs.notify({
                        text: 'User preferences saved',
                        color: 'success',
                    })
                    this.$store.dispatch('userFetch')
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'danger',
                    })
                })
        },
        userDataFetch() {
            http.get('user_staff')
                .then((response) => {
                    this.userData = response.data
                })
                .catch((error) => console.log(error))
        },
    },
}
</script>

<style></style>
